<template>
  <div class="px-1 md:px-5 pb-3">
    <!-- Content -->
    <div class="tableDivCss">
      <!-- Table Content -->
      <table class="w-full sm:bg-white rounded overflow-hidden sm:shadow-lg">
        <thead
          class="text-surface-800 rounded-l-lg sm:rounded-none mb-2 sm:mb-0 bg-surface-300"
        >
          <!-- Table Header Content -->
          <tr>
            <th
              v-if="settings.isDynamicField"
              class="heading-text hidden md:table-cell"
              @click="updateSorting('dynamicField')"
              :class="{ sorting: sortField === 'dynamicField' }"
            >
              {{ settings.dynamicField }}
              <MenuDown
                v-if="isSorted('dynamicField', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('dynamicField', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              class="heading-text"
              @click="updateSorting('name')"
              :class="{ sorting: sortField === 'name' }"
            >
              <span
                v-if="
                  (settings.layoutStyle[0] === 'floor' ||
                    settings.layoutStyle[1] === 'floor') &&
                  (settings.layoutStyle[0] === 'plot size' ||
                    settings.layoutStyle[1] === 'plot size')
                "
              >
                {{
                  settings.dynamicFieldAddress !== null
                    ? settings.dynamicFieldAddress
                    : "Unit / Plot"
                }}
              </span>
              <span
                v-else-if="
                  settings.layoutStyle[0] === 'floor' ||
                  settings.layoutStyle[1] === 'floor'
                "
              >
                {{
                  settings.dynamicFieldAddress !== null
                    ? settings.dynamicFieldAddress
                    : "Unit"
                }}
              </span>
              <span
                v-else-if="
                  settings.layoutStyle[0] === 'plot size' ||
                  settings.layoutStyle[1] === 'plot size'
                "
              >
                {{
                  settings.dynamicFieldAddress !== null
                    ? settings.dynamicFieldAddress
                    : "Plot"
                }}
              </span>

              <MenuDown
                v-if="isSorted('name', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('name', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              v-if="settings.isPrice"
              class="heading-text"
              @click="updateSorting('price')"
              :class="{ sorting: sortField === 'price' }"
            >
              Price
              <MenuDown
                v-if="isSorted('price', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('price', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              v-if="settings.isFloors"
              class="heading-text hidden md:table-cell"
              @click="updateSorting('floor')"
              :class="{ sorting: sortField === 'floor' }"
            >
              <span
                v-if="
                  (settings.layoutStyle[0] === 'floor' ||
                    settings.layoutStyle[1] === 'floor') &&
                  (settings.layoutStyle[0] === 'plot size' ||
                    settings.layoutStyle[1] === 'plot size')
                "
              >
                Floor / Size
              </span>
              <span
                v-else-if="
                  settings.layoutStyle[0] === 'floor' ||
                  settings.layoutStyle[1] === 'floor'
                "
              >
                Floor
              </span>
              <span
                v-else-if="
                  settings.layoutStyle[0] === 'plot size' ||
                  settings.layoutStyle[1] === 'plot size'
                "
              >
                Size
              </span>
              <MenuDown
                v-if="isSorted('floor', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('floor', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              class="heading-text hidden md:table-cell"
              @click="updateSorting('type')"
              :class="{ sorting: sortField === 'type' }"
            >
              <span
                v-if="
                  (settings.layoutStyle[0] === 'floor' ||
                    settings.layoutStyle[1] === 'floor') &&
                  (settings.layoutStyle[0] === 'plot size' ||
                    settings.layoutStyle[1] === 'plot size')
                "
              >
                Unit Type / Phase
              </span>
              <span
                v-else-if="
                  settings.layoutStyle[0] === 'floor' ||
                  settings.layoutStyle[1] === 'floor'
                "
              >
                Unit Type
              </span>
              <span
                v-else-if="
                  settings.layoutStyle[0] === 'plot size' ||
                  settings.layoutStyle[1] === 'plot size'
                "
              >
                Phase
              </span>
              <MenuDown
                v-if="isSorted('type', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('type', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              v-if="
                settings.isInternalArea &&
                windowWidth > 750 &&
                (settings.layoutStyle[0] === 'floor' ||
                  settings.layoutStyle[1] === 'floor')
              "
              class="heading-text"
              @click="updateSorting('internalArea')"
              :class="{ sorting: sortField === 'internalArea' }"
            >
              Internal Size
              <MenuDown
                v-if="isSorted('internalArea', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('internalArea', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              v-if="
                settings.isExternalArea &&
                windowWidth > 750 &&
                (settings.layoutStyle[0] === 'floor' ||
                  settings.layoutStyle[1] === 'floor')
              "
              class="heading-text"
              @click="updateSorting('externalArea')"
              :class="{ sorting: sortField === 'externalArea' }"
            >
              External Size
              <MenuDown
                v-if="isSorted('externalArea', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('externalArea', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              v-if="
                (settings.isTotalArea && settings.layoutStyle[0] === 'floor') ||
                settings.layoutStyle[1] === 'floor'
              "
              class="heading-text"
              @click="updateSorting('totalArea')"
              :class="{ sorting: sortField === 'totalArea' }"
            >
              Total Size
              <MenuDown
                v-if="isSorted('totalArea', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('totalArea', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              v-if="
                settings.isBedrooms &&
                (settings.layoutStyle[0] === 'floor' ||
                  settings.layoutStyle[1] === 'floor')
              "
              class="heading-text"
              @click="updateSorting('bedrooms')"
              :class="{ sorting: sortField === 'bedrooms' }"
              style="min-width: 55px"
            >
              Beds
              <MenuDown
                v-if="isSorted('bedrooms', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('bedrooms', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              v-if="settings.isBathrooms"
              class="heading-text hidden md:table-cell"
              @click="updateSorting('bathrooms')"
              :class="{ sorting: sortField === 'bathrooms' }"
            >
              Baths
              <MenuDown
                v-if="isSorted('bathrooms', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('bathrooms', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              v-if="settings.isParking"
              class="heading-text hidden md:table-cell"
              @click="updateSorting('parking')"
              :class="{ sorting: sortField === 'parking' }"
            >
              Parking
              <MenuDown
                v-if="isSorted('parking', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('parking', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              v-if="settings.isStoreroom"
              class="heading-text hidden md:table-cell"
              @click="updateSorting('storeroom')"
              :class="{ sorting: sortField === 'storeroom' }"
            >
              Storeroom
              <MenuDown
                v-if="isSorted('storeroom', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('storeroom', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              v-if="settings.isAspect"
              class="heading-text hidden md:table-cell"
              @click="updateSorting('aspect')"
              :class="{ sorting: sortField === 'aspect' }"
            >
              <span
                v-if="
                  (settings.layoutStyle[0] === 'floor' ||
                    settings.layoutStyle[1] === 'floor') &&
                  (settings.layoutStyle[0] === 'plot size' ||
                    settings.layoutStyle[1] === 'plot size')
                "
              >
                {{ settings.aspectName ?? `${DEFAULT_ASPECT_NAME} / Position` }}
              </span>
              <span
                v-else-if="
                  settings.layoutStyle[0] === 'floor' ||
                  settings.layoutStyle[1] === 'floor'
                "
              >
                {{ settings.aspectName ?? DEFAULT_ASPECT_NAME }}
              </span>
              <span
                v-else-if="
                  settings.layoutStyle[0] === 'plot size' ||
                  settings.layoutStyle[1] === 'plot size'
                "
              >
                {{ settings.aspectName ?? "Position" }}
              </span>
              <MenuDown
                v-if="isSorted('aspect', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('aspect', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              v-if="
                settings.isOutlook &&
                (settings.layoutStyle[0] === 'floor' ||
                  settings.layoutStyle[1] === 'floor')
              "
              class="heading-text hidden md:table-cell"
              @click="updateSorting('view')"
              :class="{ sorting: sortField === 'view' }"
            >
              {{ settings.outlookName }}
              <MenuDown
                v-if="isSorted('view', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('view', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <!-- Levies&Rates Hidden -->
            <th
              v-if="settings.isLevies"
              class="heading-text hidden md:table-cell"
              @click="updateSorting('levies')"
              :class="{ sorting: sortField === 'levies' }"
            >
              Levies
              <MenuDown
                v-if="isSorted('levies', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('levies', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              v-if="
                (settings.isRates && settings.layoutStyle[0] === 'floor') ||
                settings.layoutStyle[1] === 'floor'
              "
              class="heading-text hidden md:table-cell"
              @click="updateSorting('rates')"
              :class="{ sorting: sortField === 'rates' }"
            >
              Rates
              <MenuDown
                v-if="isSorted('rates', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('rates', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <!-- Expenses Hidden -->
            <th
              v-if="
                globals[0].isExpense1 &&
                globals[0].isExpense1List &&
                (globals[0].layoutStyle[0] === 'floor' ||
                  globals[0].layoutStyle[1] === 'floor')
              "
              class="heading-text hidden md:table-cell"
              @click="updateSorting('expense1')"
              :class="{ sorting: sortField === 'expense1' }"
            >
              {{ globals[0].dynamicExpense1 }}
              <MenuDown
                v-if="isSorted('expense1', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('expense1', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              v-if="
                globals[0].isExpense2 &&
                globals[0].isExpense2List &&
                (globals[0].layoutStyle[0] === 'floor' ||
                  globals[0].layoutStyle[1] === 'floor')
              "
              class="heading-text hidden md:table-cell"
              @click="updateSorting('expense2')"
              :class="{ sorting: sortField === 'expense2' }"
            >
              {{ globals[0].dynamicExpense2 }}
              <MenuDown
                v-if="isSorted('expense2', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('expense2', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              v-if="
                globals[0].isExpense3 &&
                globals[0].isExpense3List &&
                (globals[0].layoutStyle[0] === 'floor' ||
                  globals[0].layoutStyle[1] === 'floor')
              "
              class="heading-text hidden md:table-cell"
              @click="updateSorting('expense3')"
              :class="{ sorting: sortField === 'expense3' }"
            >
              {{ globals[0].dynamicExpense3 }}
              <MenuDown
                v-if="isSorted('expense3', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('expense3', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th
              v-if="
                settings.isEstRental &&
                (settings.layoutStyle[0] === 'floor' ||
                  settings.layoutStyle[1] === 'floor')
              "
              class="heading-text hidden md:table-cell"
              @click="updateSorting('guaranteedRental')"
              :class="{ sorting: sortField === 'guaranteedRental' }"
            >
              <!--          Rental Income-->
              Est. Rental
              <MenuDown
                v-if="isSorted('guaranteedRental', 'desc')"
                class="inline-block absolute top-3 -right-1"
              />
              <MenuUp
                v-if="isSorted('guaranteedRental', 'asc')"
                class="inline-block absolute top-3 -right-1"
              />
            </th>
            <th class="hidden md:table-cell heading-text-btn">Shortlist</th>
            <th class="heading-text-btn">Details</th>
            <th class="hidden md:table-cell heading-text-btn">Action</th>
          </tr>
        </thead>

        <tbody
          class="scroll"
          v-for="unit in filteredUnits.slice(0, this.initialAmount)"
          :key="unit.id"
        >
          <!-- Table Body Content -->
          <tr
            class="mb-2 sm:mb-0 bg-white"
            :class="[
              unit.status === 'reserved' ? 'reserved-list' : null,
              unit.status === 'sold' ? 'sold-list' : null,
            ]"
          >
            <!-- Unit View facing Content -->
            <td
              v-if="settings.isDynamicField"
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container twoLineContainer"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="
                border-bottom: 2px solid rgba(243, 244, 246, 1);
                word-wrap: break-word;
                white-space: pre-wrap;
              "
            >
              {{ unit.dynamicField }}
            </td>
            <!-- Name/Number Content -->
            <td
              class="p-2 truncate fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ removeLeadingZeros(unit.name) }}
            </td>
            <!-- Price && Status Content -->
            <td
              v-if="windowWidth > 750 && settings.isPrice"
              class="truncate fontSizeContainer body-item-container"
              :class="{
                'p-3': user.loggedIn,
                'price-cta-correction':
                  !user.loggedIn || !user.profile.answeredDiscountSurvey,
                'hover:bg-surface-600': unit.status === 'sold',
                'hover:bg-surface-100': unit.status !== 'sold',
              }"
              style="
                border-bottom: 2px solid rgba(243, 244, 246, 1);
                top: 0px;
                position: relative;
                overflow-wrap: break-word;
                white-space: pre-wrap;
              "
            >
              <UnitPrice
                class="relative"
                :unit="unit"
                :settings="settings"
                :show="true"
                :tableStyle="true"
                :bigPrice="false"
                :showWording="false"
                :normalPrice="true"
              />
            </td>
            <td
              v-else-if="windowWidth < 750 && settings.isPrice"
              class="hover:bg-surface-100 truncate fontSizeContainer body-item-container"
              :class="{
                'p-3': user.loggedIn,
                'price-cta-correction':
                  !user.loggedIn || !user.profile.answeredDiscountSurvey,
                'hover:bg-surface-600': unit.status === 'sold',
                'hover:bg-surface-100': unit.status !== 'sold',
              }"
              style="
                border-bottom: 2px solid rgba(243, 244, 246, 1);
                top: 0px;
                position: relative;
                justify-content: center;
                align-content: center;
                text-align: center;
                overflow-wrap: break-word;
                white-space: pre-wrap;
              "
            >
              <UnitPrice
                class="relative"
                :unit="unit"
                :settings="settings"
                :show="true"
                :tableStyle="true"
                :bigPrice="false"
                :showWording="false"
                :normalPrice="true"
              />
            </td>
            <!-- Floor Content -->
            <td
              v-if="settings.isFloors"
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.floor }}
            </td>
            <!-- Unit Type Content -->
            <td
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container twoLineContainer"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="
                border-bottom: 2px solid rgba(243, 244, 246, 1);
                word-wrap: break-word;
                white-space: pre-wrap;
              "
            >
              {{ unit.type }}
            </td>
            <!-- Area Content -->
            <td
              v-if="
                settings.isInternalArea &&
                windowWidth > 750 &&
                (settings.layoutStyle[0] === 'floor' ||
                  settings.layoutStyle[1] === 'floor')
              "
              class="p-2 truncate fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.internalArea }}m&sup2;
            </td>
            <td
              v-if="
                settings.isExternalArea &&
                windowWidth > 750 &&
                (settings.layoutStyle[0] === 'floor' ||
                  settings.layoutStyle[1] === 'floor')
              "
              class="p-2 truncate fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.externalArea }}m&sup2;
            </td>
            <td
              v-if="
                (settings.isTotalArea && settings.layoutStyle[0] === 'floor') ||
                settings.layoutStyle[1] === 'floor'
              "
              class="p-2 truncate fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.totalArea }}m&sup2;
            </td>
            <!-- Unit Spec Content -->
            <td
              v-if="
                settings.isBedrooms &&
                (settings.layoutStyle[0] === 'floor' ||
                  settings.layoutStyle[1] === 'floor')
              "
              class="p-2 truncate fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.bedrooms }}
            </td>
            <td
              v-if="settings.isBathrooms"
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.bathrooms }}
            </td>

            <td
              v-if="settings.isParking"
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.parking }}
            </td>

            <td
              v-if="settings.isStoreroom"
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.storeroom }}
            </td>

            <!-- Unit Direction facing Content -->
            <!-- Direction facing === aspect -->
            <td
              v-if="settings.isAspect"
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container twoLineContainer"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="
                border-bottom: 2px solid rgba(243, 244, 246, 1);
                word-wrap: break-word;
                white-space: pre-wrap;
              "
            >
              {{ unit.aspect }}
            </td>
            <!-- Unit View facing Content -->
            <td
              v-if="
                settings.isOutlook &&
                (settings.layoutStyle[0] === 'floor' ||
                  settings.layoutStyle[1] === 'floor')
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container twoLineContainer"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="
                border-bottom: 2px solid rgba(243, 244, 246, 1);
                word-wrap: break-word;
                white-space: pre-wrap;
              "
            >
              {{ unit.view }}
            </td>
            <!-- Unit Levies Content -->
            <td
              v-if="settings.isLevies"
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.levies && unit.levies > 0">
                {{ prettyCurrency(unit.levies.toFixed(0)) }}
              </span>

              <span
                v-else
                class="opacity-0 p-0"
                :class="
                  unit.status === 'sold'
                    ? 'hover:bg-surface-600'
                    : 'hover:bg-surface-100'
                "
              >
              </span>
            </td>
            <!-- Unit Rates Content -->
            <td
              v-if="
                (settings.isRates && settings.layoutStyle[0] === 'floor') ||
                settings.layoutStyle[1] === 'floor'
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.rates && unit.rates > 0">
                {{ prettyCurrency(unit.rates.toFixed(0)) }}
              </span>

              <span
                v-else
                class="opacity-0 p-0"
                :class="
                  unit.status === 'sold'
                    ? 'hover:bg-surface-600'
                    : 'hover:bg-surface-100'
                "
              >
              </span>
            </td>
            <!-- Unit expense 1 Content -->
            <td
              v-if="
                globals[0].isExpense1 &&
                globals[0].isExpense1List &&
                (globals[0].layoutStyle[0] === 'floor' ||
                  globals[0].layoutStyle[1] === 'floor')
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.expense1 && unit.expense1 > 0">
                {{ prettyCurrency(unit.expense1) }}
              </span>

              <span
                v-else
                class="opacity-0 p-0"
                :class="
                  unit.status === 'sold'
                    ? 'hover:bg-surface-600'
                    : 'hover:bg-surface-100'
                "
              >
              </span>
            </td>
            <!-- Unit expense 2 Content -->
            <td
              v-if="
                globals[0].isExpense2 &&
                globals[0].isExpense2List &&
                (globals[0].layoutStyle[0] === 'floor' ||
                  globals[0].layoutStyle[1] === 'floor')
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.expense2 && unit.expense2 > 0">
                {{ prettyCurrency(unit.expense2) }}
              </span>

              <span
                v-else
                class="opacity-0 p-0"
                :class="
                  unit.status === 'sold'
                    ? 'hover:bg-surface-600'
                    : 'hover:bg-surface-100'
                "
              >
              </span>
            </td>
            <!-- Unit expense 3 Content -->
            <td
              v-if="
                globals[0].isExpense3 &&
                globals[0].isExpense3List &&
                (globals[0].layoutStyle[0] === 'floor' ||
                  globals[0].layoutStyle[1] === 'floor')
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.expense3 && unit.expense3 > 0">
                {{ prettyCurrency(unit.expense3) }}
              </span>

              <span
                v-else
                class="opacity-0 p-0"
                :class="
                  unit.status === 'sold'
                    ? 'hover:bg-surface-600'
                    : 'hover:bg-surface-100'
                "
              >
              </span>
            </td>
            <!-- Unit Rental Income Content -->
            <td
              v-if="
                settings.isEstRental &&
                (settings.layoutStyle[0] === 'floor' ||
                  settings.layoutStyle[1] === 'floor')
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.guaranteedRental && unit.guaranteedRental > 0">
                {{ prettyCurrency(unit.guaranteedRental.toFixed(0)) }}
              </span>

              <span
                v-else
                class="z-10 pt-2 pb-2 pr-5 pl-5 rounded"
                :class="
                  unit.status === 'sold'
                    ? 'hover:bg-surface-600'
                    : 'hover:bg-surface-100'
                "
              >
                NA
              </span>
            </td>
            <td
              class="p-2 cursor-pointer hidden md:table-cell fontSizeContainerBtn body-item-container"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span>
                <AddToShortlistTable
                  :unitID="unit.id"
                  :unitName="unit.name"
                  :unit="unit"
                  :shortListCount="unit.shortlistIncrement"
                />
              </span>
            </td>
            <!-- Unit View Content -->
            <td
              class="p-2 cursor-pointer body-item-container"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <button
                v-if="unit.customIdViewButton"
                class="p-2 w-full text-white fontSizeContainerBtn md:fontSizeContainerBtn font-semibold uppercase tracking-wider bg-surface rounded duration-150 hover:bg-surface-600"
                v-on:click="
                  $emit('update:unit-dialog-data', { open: true, data: unit })
                "
                :id="unit.customIdViewButton"
              >
                More Info
              </button>

              <button
                v-else
                class="p-2 w-full text-white fontSizeContainerBtn md:fontSizeContainerBtn font-semibold uppercase tracking-wider bg-surface rounded duration-150 hover:bg-surface-600"
                v-on:click="
                  $emit('update:unit-dialog-data', { open: true, data: unit })
                "
              >
                More Info
              </button>
            </td>
            <!-- Unit Status Content -->
            <td
              class="p-2 cursor-pointer hidden md:table-cell body-item-container"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <button
                v-if="
                  unit.showEnquireButton ||
                  (!unit.showEnquireButton &&
                    unit.showPriceAlternative?.enabled)
                "
                class="bg-primary w-full fontSizeContainerBtn font-semibold uppercase tracking-wider duration-150 hover:bg-primary-600 rounded text-primary-inverse"
                :class="[windowWidth <= 750 ? 'p-1' : 'p-2']"
                @click.prevent="openContactDialog(unit.name, unit.standNumber)"
              >
                ENQUIRE
              </button>
              <template v-else>
                <div v-if="settings.isGlobalOverrideAction === true">
                  <a :href="settings.globalOverrideAction" target="_blank">
                    <button
                      class="bg-primary hover:bg-primary-600 text-primary-inverse w-full items-center p-2 fontSizeContainerBtn font-semibold uppercase tracking-wider cursor-pointer rounded"
                    >
                      {{ settings.globalOverrideActionButton }}
                    </button>
                  </a>
                </div>

                <div v-else>
                  <div v-if="unit.isOverrideAction === true">
                    <a :href="unit.unitOverrideAction" target="_blank">
                      <button
                        class="bg-primary hover:bg-primary-600 text-primary-inverse w-full items-center p-2 fontSizeContainerBtn font-semibold uppercase tracking-wider cursor-pointer rounded"
                      >
                        {{ unit.unitOverrideActionButton }}
                      </button>
                    </a>
                  </div>

                  <div class="has-tooltip" v-else-if="settings.allowReserve">
                    <button
                      disabled
                      class="bg-surface-500 w-full items-center p-2 fontSizeContainerBtn font-semibold uppercase tracking-wider cursor-not-allowed rounded text-primary-inverse"
                      v-if="
                        unit.status === 'pending' ||
                        unit.status === 'pendingUnitDetails' ||
                        unit.status === 'pendingPayment'
                      "
                    >
                      Pending
                    </button>

                    <button
                      disabled
                      class="bg-surface-500 w-full items-center p-2 fontSizeContainerBtn font-semibold uppercase tracking-wider cursor-not-allowed rounded text-primary-inverse"
                      v-if="unit.status === 'reserved'"
                    >
                      Reserved
                    </button>

                    <button
                      disabled
                      class="bg-surface-500 w-full items-center p-2 fontSizeContainerBtn font-semibold uppercase tracking-wider cursor-not-allowed rounded text-primary-inverse"
                      v-if="unit.status === 'sold'"
                    >
                      Sold
                    </button>

                    <div>
                      <button
                        class="relative bg-surface-400 rounded items-center w-full px-4 py-2 text-white fontSizeContainerBtn tracking-wider duration-150"
                        v-if="unit.status === 'available' && disableReserve"
                      >
                        <span class="text-md font-semibold uppercase">
                          Reserve
                        </span>
                        <span class="tooltip -bottom-0 -right-0"
                          >This reserve button will become active on
                          launch</span
                        >
                      </button>

                      <button
                        :disabled="isLoading"
                        class="p-2 w-full text-primary-inverse fontSizeContainerBtn font-semibold uppercase tracking-wider duration-150 rounded bg-primary hover:bg-primary-600"
                        @click="unitReserve(unit.name, unit.id)"
                        v-else-if="
                          unit.status === 'available' &&
                          !disableReserve &&
                          !isPendingBlocked
                        "
                      >
                        <span v-if="isLoading && unit.id === unitIdOnClick">
                          <clipLoader :loading="isLoading" />
                        </span>
                        <span v-else>Reserve</span>
                      </button>

                      <!--                  Max Reservable Units Button-->
                      <button
                        class="relative bg-surface-400 rounded items-center w-full px-4 py-2 text-white fontSizeContainerBtn tracking-wider duration-150"
                        v-if="
                          unit.status === 'available' &&
                          !disableReserve &&
                          isPendingBlocked
                        "
                      >
                        <span class="text-md font-semibold uppercase">
                          {{ settings.maxReservableUnitsHomeScreenButtons }}
                        </span>
                        <span class="tooltip -bottom-0 -right-0">
                          {{ settings.maxReservableUnitsHomeScreenTooltip }}
                        </span>
                      </button>
                    </div>
                  </div>

                  <div v-else>
                    <button
                      class="bg-primary w-full fontSizeContainerBtn font-semibold uppercase tracking-wider duration-150 hover:bg-primary-600 rounded text-primary-inverse"
                      :class="[windowWidth <= 750 ? 'p-1' : 'p-2']"
                      @click.prevent="
                        openContactDialog(unit.name, unit.standNumber)
                      "
                    >
                      ENQUIRE
                    </button>
                  </div>
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Content -->
    <div
      class="table-footer md:hidden flex flex-row items-center justify-center pb-1"
      style="box-shadow: 0 -5px 5px -5px #ccc"
    >
      <div
        class="uppercase font-semibold mx-1 fontSizeContainer cursor-pointer"
        :class="{ active: status === 'all' }"
        @click="$emit('update-status', 'all')"
      >
        Show All <span class="text-surface-400">{{ total }}</span>
      </div>
      <div
        class="uppercase font-semibold mx-1 fontSizeContainer cursor-pointer"
        :class="{ active: status === 'available-only' }"
        @click="$emit('update-status', 'available-only')"
      >
        Available Only <span class="text-surface-400">{{ availableOnly }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import UnitPrice from "../shared/UnitPrice.vue";
import { mapGetters } from "vuex";
import AddToShortlistTable from "./AddToShortlistTable.vue";
import MenuDown from "vue-material-design-icons/MenuDown.vue";
import MenuUp from "vue-material-design-icons/MenuUp.vue";
import { APP_TITLE, APP_ADMIN_PAGINATION } from "@/constants.js";
import { unitsCollection } from "@/firebaseCompat.js";
import firebase from "firebase/compat/app";
import clipLoader from "../shared/ClipLoader.vue";
import { sort } from "@/mixins/helpers.js";
import { DEFAULT_ASPECT_NAME } from "@/constants/index.js";

export default {
  name: "MainUnitReserveListTable",
  mixins: [sort],
  components: {
    AddToShortlistTable,
    UnitPrice,
    MenuDown,
    MenuUp,
    clipLoader,
  },
  props: {
    msTillLaunchTime: {
      type: Number,
    },
    units: {
      default: [],
    },
    status: {
      type: String,
    },
    total: {
      type: Number,
    },
    availableOnly: {
      type: Number,
    },
    guaranteedRentals: {
      type: Number,
    },
    settings: {
      type: Object,
    },
    sortField: {
      type: String,
    },
    sortDirection: {
      type: String,
    },
    unitDialogData: {
      type: Object,
    },
  },
  data() {
    return {
      DEFAULT_ASPECT_NAME,
      unitIdOnClick: "",
      isLoading: false,
      currentSort: "name",
      currentSortDir: "asc",
      pageSize: APP_ADMIN_PAGINATION,
      currentPage: 1,
      title: APP_TITLE,
      windowWidth: window.innerWidth,
      initialAmount: 50,
      addedAmount: 150,
      roleStatuses: ["admin", "admin-viewonly", "superadmin"],
    };
  },
  methods: {
    handleScroll() {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 250
      ) {
        this.addToUnitsTotal();
      }
    },
    addToUnitsTotal() {
      this.initialAmount = this.initialAmount + this.addedAmount;
    },
    isSorted(field, direction) {
      if (this.sortField === field && this.localSortDirection === direction) {
        return true;
      }
      return false;
    },

    updateSorting(field) {
      if (this.sortField === field && this.localSortDirection === "asc") {
        this.$emit("update-sorting", { field: field, direction: "desc" });
      } else {
        this.$emit("update-sorting", { field: field, direction: "asc" });
      }
    },
    setTooltip() {
      this.$emit("update:tooltip", true);
    },
    unitReserve(unitName, unitId) {
      this.unitIdOnClick = unitId;
      this.isLoading = true;

      unitsCollection
        .doc(unitId)
        .update({
          pendingCountDownTimerStart: firebase.firestore.Timestamp.now(),
          pendingCountDownTimerExpires: firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          this.$router.push({
            name: "reserve",
            params: { name: unitName },
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    openContactDialog(unitName, unitErf) {
      this.emitter.emit("update:unit-contact-data", {
        open: true,
        unitName,
        unitErf,
      });
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    localSortDirection: {
      get() {
        return this.sortDirection;
      },
      set(newValue) {
        this.$emit("update:sortDirection", newValue);
      },
    },
    ...mapGetters({
      user: "user",
      launchDateMilliseconds: "launchDateMilliseconds",
      isPendingBlocked: "isPendingBlocked",
      globals: "globals",
    }),
    filteredUnits: function () {
      return this.mixinFilteredUnits;
    },
    disableReserve: function () {
      if (this.msTillLaunchTime >= 0) {
        if (this.units.isOneDayOnlyDiscount === true) {
          return false;
        }
        let userProfileRole =
          this.user.profile === null ? "user" : this.user.profile.role;
        if (
          this.roleStatuses.includes(userProfileRole) &&
          this.settings.bypassAdmin === true
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
};
</script>

<style scoped>
.fontSizeContainer {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.fontSizeContainerBtn {
  font-size: 0.875rem;
  line-height: 1.6rem;
}

.twoLineContainer {
  max-width: 110px !important;
}

.table-footer {
  height: 50px;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 2px solid #fff;
}

.tableDivCss {
  width: 100%;
  overflow-x: auto;
}

.table-footer .active {
  border-bottom: 2px solid #aa9461;
}

.price-cta-correction {
  padding-top: 8px;
  padding-left: 10px;
  padding-bottom: 15px;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.tooltip .tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 9999;
}

thead tr:not(:first-child) {
  display: none;
}

.sorting {
  background: #ffffff33;
}

.heading-text {
  position: relative;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
  padding: 0.5rem;
}

.heading-text:hover {
  opacity: 0.7;
  background: #ffffff33;
}

.scroll {
  overflow-x: scroll;
}

.body-item-container {
  line-height: 1rem;
  text-align: center;
  padding: 0.5rem;
}

.heading-text-btn {
  padding: 0.5rem;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
}

.reserved-list {
  background: #6c727f;
  color: #fff;
}

.sold-list {
  background: #3e404c;
  color: #fff;
}

@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }
}

@media (min-width: 768px) {
  .heading-text {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .heading-text-btn {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
</style>
