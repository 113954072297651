<script setup>
import { ref, onMounted, computed, onBeforeUnmount, watch, toRefs } from "vue";
import { firestoreStore, adjustShade } from "@/utils";
import * as d3 from "d3";
import { useSound } from "@vueuse/sound";
import saleSfx from "../../assets/sound/ding.mp3";
import { useStore } from "vuex";
import { useDebounceFn } from "@vueuse/core/index.cjs";

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  fitImageHeight: {
    type: Boolean,
    required: false,
    default() {
      return true;
    },
  },
  pins: {
    type: Array,
    required: false,
    default() {
      return [];
    },
  },
  editable: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  disableGestures: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  showZoomPercentage: {
    type: Boolean,
    required: false,
    default() {
      return true;
    },
  },
});
const { src, pins } = toRefs(props);

const emit = defineEmits({
  addPin: ({ x, y }) =>
    [x, y].every((property) => typeof property === "number"),
  updatePin: ({ index, x, y }) =>
    [index, x, y].every((property) => typeof property === "number"),
  clickPin: ({ event, index, unit }) =>
    event instanceof MouseEvent &&
    typeof index === "number" &&
    (typeof unit === "undefined" || typeof unit === "string"),
});

const {
  state: { theme },
} = firestoreStore();
const backgroundColour = computed(() => {
  if (!theme) return "#ffffff";

  return adjustShade(theme.value.colours.surface, -40);
});

const svg = ref(null);
const k = ref(1);
const scaleUpperLimit = ref(undefined);
const edit = ref(false);

const clickPin = (event, pin) => {
  event.stopPropagation();
  emit("clickPin", {
    event,
    index: pin.index,
    unit: pin?.unit,
  });
};

const dragBehaviour = d3
  .drag()
  .on("start", function () {
    d3.select(this).classed("cursor-grabbing", true);
  })
  .on("drag", function ({ x, y }, d) {
    const pin = d3.select(this);
    const adjustedX = d.coordinateAdjuster(x, pin.node());
    const adjustedY = d.coordinateAdjuster(y, pin.node());
    d.x = x;
    d.y = y;
    pin.attr("x", adjustedX).attr("y", adjustedY);
  })
  .on("end", function (_, { index, x, y }) {
    d3.select(this).classed("cursor-grabbing", false);
    emit("updatePin", { index, x, y });
  });

const getComparablePin = ({
  item: { classList: _, ...itemRest },
  itemCreator: __,
  coordinateAdjuster: ___,
  ...comparable
}) => ({
  ...comparable,
  item: itemRest,
});

const manipulatePins = ({ newPins, oldPins }) => {
  const gElement = d3.select(svg.value).select("g");
  const pinsElements = gElement
    .selectAll(".pin")
    .data(newPins, (pin) => pin?.index);

  pinsElements.join(
    (enter) =>
      enter
        .append(({ itemCreator }) => itemCreator())
        .attr("x", ({ x, coordinateAdjuster }, i, items) =>
          coordinateAdjuster(x, d3.select(items[i]).node()),
        )
        .attr("y", ({ y, coordinateAdjuster }, i, items) =>
          coordinateAdjuster(y, d3.select(items[i]).node()),
        )
        .on("click", !props.editable || edit.value ? clickPin : null)
        .call((selection) => {
          if (props.editable && edit.value) {
            selection.call(dragBehaviour);
          }
        })
        .classed(
          `pin ${!props.editable ? "cursor-pointer" : edit.value ? "cursor-grab" : ""}`,
          true,
        )
        .select("g")
        .attr("transform", "scale(0)")
        .transition()
        .delay((_, i) => i * 25)
        .duration(400)
        .ease(d3.easeCubicInOut)
        .attr("transform", "scale(1)"),
    (update) =>
      update
        .each(function ({ item }, i) {
          const pin = d3.select(this);
          const oldClassList = oldPins[i]?.item.classList ?? "";
          const classList = item.classList ?? "";

          if (oldClassList === classList) return;

          pin.classed(oldClassList, false);
          pin.classed(classList, true);
        })
        .filter((pin, i) => {
          const comparable = getComparablePin(pin);

          const oldPin = oldPins[i];
          if (oldPin === undefined) return true;

          const oldComparable = getComparablePin(oldPin);

          return JSON.stringify(comparable) !== JSON.stringify(oldComparable);
        })
        .transition()
        .duration(400)
        .ease(d3.easeCubicInOut)
        .attr("x", ({ x, coordinateAdjuster }, i, items) =>
          coordinateAdjuster(x, d3.select(items[i]).node()),
        )
        .attr("y", ({ y, coordinateAdjuster }, i, items) =>
          coordinateAdjuster(y, d3.select(items[i]).node()),
        )
        .select("g")
        .transition()
        .duration(400)
        .ease(d3.easeCubicInOut)
        .attr("transform", "scale(1.4)")
        .transition()
        .duration(400)
        .ease(d3.easeCubicInOut)
        .attr("transform", "scale(1)")
        .on("start", function () {
          const pinGroup = d3.select(this);

          pinGroup
            .select("circle")
            .transition()
            .duration(400)
            .ease(d3.easeCubicInOut)
            .attr("stroke", (data) => data.item.colours.border)
            .attr("fill", (data) => data.item.colours.background);

          const textGroup = pinGroup.select("g");
          textGroup
            .select("text.name")
            .transition()
            .duration(400)
            .ease(d3.easeCubicInOut)
            .attr("fill", (data) => data.item.colours.textName)
            .text((data) => data.item.name);

          textGroup
            .select("text.price")
            .transition()
            .duration(400)
            .ease(d3.easeCubicInOut)
            .attr("fill", (data) => data.item.colours.textPrice)
            .text((data) => data.item.price);
        }),
    (exit) =>
      exit
        .select("g")
        .transition()
        .duration(150)
        .ease(d3.easeCubicInOut)
        .attr("transform", "scale(1.4)")
        .transition()
        .duration(150)
        .ease(d3.easeCubicInOut)
        .attr("transform", "scale(0)")
        .remove(),
  );
};

const minRate = 0.75;
const maxRate = 1.3;
const rateIncrease = 0.05;
const rateDecay = 80000;
const playbackRate = ref(minRate);
const { play } = useSound(saleSfx, {
  playbackRate,
});
let previousPlay = Date.now();

function playSound() {
  playbackRate.value = Math.max(
    playbackRate.value - (Date.now() - previousPlay) / rateDecay,
    minRate,
  );
  playbackRate.value = Math.min(playbackRate.value + rateIncrease, maxRate);

  previousPlay = Date.now();
  play();
}

const store = useStore();
const user = computed(() => store.getters.user);

watch(
  pins,
  (newPins, oldPins) => {
    manipulatePins({ newPins, oldPins });

    if (!props.disableGestures && user.value.profile.role === "superadmin") {
      // i.e. if in fullscreen mode
      newPins.forEach((newPin, index) => {
        if (
          oldPins[index] &&
          oldPins[index].item.price !== "SOLD" &&
          newPin.item.price === "SOLD"
        ) {
          playSound();
        }
      });
    }
  },
  { deep: true },
);

watch(edit, () => {
  const imageElement = d3.select(svg.value).select("image");
  const pinsElements = d3.select(svg.value).select("g").selectAll(".pin");
  if (!edit.value) {
    imageElement.on("click", null);
    pinsElements.on(".drag", null).classed("cursor-grab", false);
    pinsElements.on("click", null);
  } else {
    imageElement.on("click", (event) => {
      const [x, y] = d3.pointer(event, imageElement.node());
      emit("addPin", {
        x: x * scaleUpperLimit.value,
        y: y * scaleUpperLimit.value,
      });
    });
    pinsElements.on("click", clickPin);
    pinsElements.call(dragBehaviour).classed("cursor-grab", true);
  }
});

const cursor = computed(() => {
  if (props.disableGestures) return "cursor-pointer";

  if (edit.value) return "cursor-copy";

  return "cursor-move";
});
const zoomPercentage = computed(
  () => Math.round((k.value / scaleUpperLimit.value) * 100) || 0,
);

let widthImage, heightImage;
const setImageDimensions = async () => {
  [widthImage, heightImage] = await new Promise((resolve) => {
    const image = new Image();
    image.src = src.value;
    image.onload = () => {
      resolve([image.width, image.height]);
    };
  });
};

const div = ref(null);
const calculateDimensions = () => {
  const { width: widthDiv, height: heightDiv } =
    div.value.getBoundingClientRect();

  let width = widthImage;
  let height = heightImage;

  if (props.fitImageHeight) {
    if (widthImage >= widthDiv) {
      width = widthDiv;
      height = widthDiv * (heightImage / widthImage);
    }
  } else if (widthImage > heightImage) {
    if (heightImage >= heightDiv) {
      const _width = heightDiv * (widthImage / heightImage);
      if (_width <= widthDiv) {
        width = widthDiv;
        height = widthDiv * (heightImage / widthImage);
      } else {
        height = heightDiv;
        width = heightDiv * (widthImage / heightImage);
      }
    }
  } else {
    if (widthImage >= widthDiv) {
      const _height = widthDiv * (heightImage / widthImage);
      if (_height <= heightDiv) {
        height = heightDiv;
        width = heightDiv * (widthImage / heightImage);
      } else {
        width = widthDiv;
        height = widthDiv * (heightImage / widthImage);
      }
    }
  }

  return {
    widthDiv,
    heightDiv,
    width,
    height,
  };
};

let isUnmounted = false;
const handleTrackpadEvents = () => {
  if (isUnmounted) return;

  const { widthDiv, heightDiv, width, height } = calculateDimensions();
  const svgElement = d3.select(svg.value);

  svgElement.on("wheel", (event) => {
    event.preventDefault();
    const { ctrlKey, deltaX, deltaY } = event;
    if (ctrlKey) return;

    const { x, y, k } = d3.zoomTransform(svgElement.node());

    let transformX = x;
    const newTransformX = x - deltaX;
    if (newTransformX < 0 && newTransformX > widthDiv - width * k) {
      transformX = newTransformX;
    }

    let transformY = y;
    const newTransformY = y - deltaY;
    const relevantHeight = props.fitImageHeight ? height : heightDiv;
    if (newTransformY < 0 && newTransformY > relevantHeight - height * k) {
      transformY = newTransformY;
    }

    svgElement.call(
      zoomBehaviour.transform,
      d3.zoomIdentity.translate(transformX, transformY).scale(k),
    );
  });
};
const handleTrackpadEventsDebounce = useDebounceFn(handleTrackpadEvents, 1000);

const svgElementHeight = ref([]);
let zoomBehaviour;
const initialize = () => {
  if (!props.fitImageHeight) {
    div.value.classList.add("h-full");
  }

  const { widthDiv, heightDiv, width, height } = calculateDimensions();

  scaleUpperLimit.value = Math.min(widthImage / width, heightImage / height);

  const svgElement = d3.select(svg.value);
  svgElement
    .attr("width", "100%")
    .attr("height", props.fitImageHeight ? height : heightDiv);

  const imageElement = svgElement.append("image");
  imageElement.attr("href", src.value);
  imageElement.attr("width", width).attr("height", height);

  const gElement = svgElement.append("g");
  gElement.attr("transform", `scale(${k.value / scaleUpperLimit.value})`);

  manipulatePins({ newPins: pins.value });

  zoomBehaviour = d3
    .zoom()
    .filter((event) => {
      if (event.type !== "wheel") return true;
      return event.ctrlKey;
    })
    .scaleExtent([1, scaleUpperLimit.value])
    .translateExtent([
      [0, 0],
      [width, height],
    ])
    .on("zoom", ({ transform }) => {
      if (k.value !== transform.k) {
        k.value = transform.k;
      }

      imageElement.attr("transform", transform);
      gElement.attr(
        "transform",
        `translate(${transform.x}, ${transform.y}) scale(${transform.k / scaleUpperLimit.value})`,
      );
    });

  svgElement.call(zoomBehaviour.transform, d3.zoomIdentity.scale(k.value));

  // Center image within it's container.
  let imageIsThin = width < widthDiv;
  let imageIsShort = height < heightDiv;
  if (imageIsThin || imageIsShort) {
    svgElement.call(
      zoomBehaviour.transform,
      d3.zoomIdentity.translate(
        imageIsThin ? (widthDiv - width) / 2 : 0,
        imageIsShort ? (heightDiv - height) / 2 : 0,
      ),
    );
  }

  if (props.disableGestures) return;

  svgElement.call(zoomBehaviour);
  handleTrackpadEvents();
};

const resize = () => {
  const { widthDiv, heightDiv, width, height } = calculateDimensions();

  scaleUpperLimit.value = Math.min(widthImage / width, heightImage / height);

  const svgElement = d3.select(svg.value);
  svgElement.attr("height", props.fitImageHeight ? height : heightDiv);

  const imageElement = svgElement.select("image");
  imageElement.attr("width", width).attr("height", height);

  zoomBehaviour.scaleExtent([1, scaleUpperLimit.value]).translateExtent([
    [0, 0],
    [width, height],
  ]);

  if (k.value !== 1) {
    k.value = 1;
  }

  let translateX = 0;
  let translateY = 0;
  if (width < widthDiv) {
    translateX = (widthDiv - width) / 2;
  }

  svgElement.call(
    zoomBehaviour.transform,
    d3.zoomIdentity.scale(k.value).translate(translateX, translateY),
  );

  if (props.disableGestures) return;

  handleTrackpadEventsDebounce();
};

onMounted(async () => {
  await setImageDimensions();
  initialize();
  window.addEventListener("resize", resize);
});
onBeforeUnmount(() => {
  if (edit.value) {
    const imageElement = d3.select(svg.value).select("image");
    imageElement.on("click", null);
  }
  window.removeEventListener("resize", resize);
  isUnmounted = true;
});
</script>

<template>
  <div ref="div" class="relative w-full">
    <svg
      ref="svg"
      :class="[cursor, svgElementHeight]"
      :style="`background-color: ${backgroundColour}`"
    ></svg>
    <div
      v-if="showZoomPercentage"
      :class="`absolute right-1 top-1 rounded py-1 px-2 bg-primary-inverse/70 flex items-center text-surface-900 ${cursor}`"
    >
      <i class="pi pi-search mr-2 text-sm"></i>
      <span class="select-none">{{ zoomPercentage }}%</span>
    </div>

    <div
      v-if="editable"
      class="absolute right-1 bottom-1 rounded py-1 px-2 bg-primary-inverse/70 flex flex-col items-end cursor-default"
    >
      <span class="text-surface-900 select-none">Edit Units</span>
      <p-inputswitch v-model="edit"></p-inputswitch>
    </div>
  </div>
</template>
