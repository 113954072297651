import { createApp } from "vue";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import store from "../store.js";
import App from "./App.vue";
import "./assets/tailwind.css";
import router from "./router/index.js";
import mitt from "mitt";
import * as Sentry from "@sentry/vue";
import packageJson from "../package.json";
import { SENTRY_DSN } from "./constants.js";
import VueSocialChat from "vue-social-chat";
import "vue-social-chat/dist/style.css";
import VueClickAway from "vue3-click-away";
import { handleUserPresence } from "./presence.js";
import { profilesCollection } from "./firebaseCompat.js";
import { pinia, useUserStore, STATUS } from "@/state/index.js";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

// PRIMEVUE
import PrimeVue from "primevue/config";
import Aura from "@/presets/aura/index.js";
import "primeicons/primeicons.css";
// Services
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
// Directives
import Tooltip from "primevue/tooltip";
// Components
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Datatable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import IconField from "primevue/iconfield";
import Image from "primevue/image";
import InputIcon from "primevue/inputicon";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import MultiSelect from "primevue/multiselect";
import Panel from "primevue/panel";
import ProgressBar from "primevue/progressbar";
import Row from "primevue/row";
import SelectButton from "primevue/selectbutton";
import Skeleton from "primevue/skeleton";
import Tag from "primevue/tag";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import ProgressSpinner from "primevue/progressspinner";
import ConfirmDialog from "primevue/confirmdialog";
import Slider from "primevue/slider";
import MeterGroup from "primevue/metergroup";
import RadioButton from "primevue/radiobutton";
import { firebaseApp } from "@/firebase.js";
import { VueFire } from "vuefire";
import prettyCurrency from "@/mixins/prettyCurrency.js";

const app = createApp(App);

app.use(VueFire, {
  firebaseApp,
});

// === Sentry Initialization ===

Sentry.init({
  app,
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.captureConsoleIntegration({
      levels: ["error"],
    }),
  ],

  beforeSend(event) {
    // Firestore connection issue that should've been resolved since v9.22: https://github.com/firebase/firebase-js-sdk/issues/1674
    if (event.message?.includes("Could not reach Cloud Firestore backend.")) {
      logEventCancelled(event);
      return null;
    }

    const exceptionFirstValue = event.exception?.values?.[0];
    // Apple product "Load Failed" errors. "Load Failed errors occur on Apple devices when there is an error with Fetch API."
    // https://sentry.io/answers/load-failed-javascript/
    // https://stackoverflow.com/a/60860369
    // https://github.com/sveltejs/kit/issues/5208#issuecomment-1416860416
    if (
      exceptionFirstValue?.type === "TypeError" &&
      (exceptionFirstValue?.value?.startsWith("Load failed") ||
        exceptionFirstValue?.value?.startsWith("cancelled") ||
        exceptionFirstValue?.value?.startsWith(
          "The network connection was lost.",
        ) ||
        exceptionFirstValue?.value?.startsWith(
          // chunk splitting + new releases (handled by reload triggered in router.onError)
          "Failed to fetch dynamically imported module:",
        ) ||
        exceptionFirstValue?.value?.startsWith(
          // chunk splitting + new releases (handled by reload triggered in router.onError)
          "Importing a module script failed.",
        ))
    ) {
      logEventCancelled(event);
      return null;
    }

    if (
      exceptionFirstValue?.type === "Error" &&
      (exceptionFirstValue?.value?.startsWith(
        // after a user has been away from the site for too long
        "UnknownError: Connection to Indexed Database server lost.",
      ) ||
        exceptionFirstValue?.value?.startsWith(
          // chunk splitting + new releases (handled by reload triggered in router.onError)
          "Unable to preload CSS for",
        ))
    ) {
      logEventCancelled(event);
      return null;
    }

    // filter out UnhandledRejection errors that have no information
    // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-828834651
    if (
      exceptionFirstValue?.type === "UnhandledRejection" &&
      exceptionFirstValue?.value?.startsWith(
        "Non-Error promise rejection captured with value:",
      )
    ) {
      logEventCancelled(event);
      return null;
    }

    return event;
  },

  // https://docs.sentry.io/platforms/javascript/guides/vue/features/component-tracking/
  trackComponents: true,
  hooks: ["activate", "create", "mount", "update", "unmount"],

  // Performance Monitoring
  tracesSampleRate: 0.05,

  release: import.meta.env.VITE_SENTRY_PACKAGE + "@" + packageJson.version,
  dist: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  environment: import.meta.env.VITE_FIREBASE_PROJECT_ID,

  profilesSampleRate: 0.05,

  attachStacktrace: true,
});

// === Helpers ===

// these cancelled events we should collect somewhere (other than sentry) so
// that we can ensure that we're not cancelling actionable events as well
const logEventCancelled = (event) =>
  console.log({
    message: "Sentry Event Send cancelled",
    event,
  });

// === Mixins ===
app.mixin(prettyCurrency);

// === Authentication ===

firebase.auth().onAuthStateChanged(async (user) => {
  try {
    // Legacy Vuex, to be removed in the future.
    store.dispatch("fetchUser", user);
    // ----------------------------------------

    if (user !== null) {
      if (user.isAnonymous) return;

      handleUserPresence(user.uid);

      // Legacy Vuex, to be removed in the future.
      await updateUserProfile(user);
      // ----------------------------------------

      const { status, listenToUser } = useUserStore();
      if (status === STATUS.PENDING || status === STATUS.LISTENING) return;

      const { uid, email } = user;
      await listenToUser(uid, email);
      return;
    }

    await firebase.auth().signInAnonymously();
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});

// Legacy Vuex, to be removed in the future.
const updateUserProfile = async (user) => {
  await new Promise((resolve, reject) => {
    profilesCollection.doc(user.uid).onSnapshot(
      (doc) => {
        if (doc.exists) {
          store.dispatch("setProfile", doc.data());
          resolve(doc.data());
        }
      },
      (error) => {
        Sentry.captureException(error);
        reject(error);
      },
    );
  });
};
// ----------------------------------------

// === Directives ===

const clickOutside = {
  beforeMount: function (el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
};

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app.use(VueSocialChat);
app.use(VueClickAway);

app.directive("click-outside", clickOutside);

// Whenever we add a new component we should also add it's matching Aura preset (found at https://tailwind.primevue.org/builder)
// to the folder src/presets/aura and be sure to update the preset index.js file as well
app.use(PrimeVue, {
  unstyled: true,
  pt: Aura,
  ripple: true,
});
// Services
app.use(ToastService);
app.use(ConfirmationService);
// Directives
app.directive("tooltip", Tooltip);
// Components
// these first components can't be aliased and are only used as a dependencies, so it does not affect our coding much
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Row", Row);
// Aliased Components
app.component("p-button", Button);
app.component("p-calendar", Calendar);
app.component("p-checkbox", Checkbox);
app.component("p-datatable", Datatable);
app.component("p-dialog", Dialog);
app.component("p-divider", Divider);
app.component("p-dropdown", Dropdown);
app.component("p-fileupload", FileUpload);
app.component("p-iconfield", IconField);
app.component("p-image", Image);
app.component("p-inputicon", InputIcon);
app.component("p-inputswitch", InputSwitch);
app.component("p-inputtext", InputText);
app.component("p-inputnumber", InputNumber);
app.component("p-multiselect", MultiSelect);
app.component("p-panel", Panel);
app.component("p-progressbar", ProgressBar);
app.component("p-progressspinner", ProgressSpinner);
app.component("p-selectbutton", SelectButton);
app.component("p-skeleton", Skeleton);
app.component("p-tag", Tag);
app.component("p-textarea", Textarea);
app.component("p-toast", Toast);
app.component("p-confirmdialog", ConfirmDialog);
app.component("p-slider", Slider);
app.component("p-metergroup", MeterGroup);
app.component("p-radiobutton", RadioButton);

app.use(router);
app.use(store);
app.use(pinia);

app.mount("#app");
