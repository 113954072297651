const firebaseConfig = {
  projectId: "the-dawn-h",
  appId: "1:530868478200:web:ac9b800d29208842669b0d",
  databaseURL:
    "https://the-dawn-h-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "the-dawn-h.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyDgDq28-MylwAhJqnN424Kj-ik0Z7OiPUE",
  authDomain: "the-dawn-h.firebaseapp.com",
  messagingSenderId: "530868478200",
  measurementId: "G-9RJ35W4RJM",
};

export default firebaseConfig;
