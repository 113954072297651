<template>
  <!-- Filter Menu Item Content -->
  <div class="relative w-full">
    <button
      @click="toggleDropdown"
      class="block flex flex-row px-3 py-2 items-center w-full text-sm font-semibold text-left bg-transparent border rounded-md border-surface-300 hover:text-surface-900 focus:text-surface-900 hover:bg-surface-200 focus:bg-surface-200 focus:outline-none focus:shadow-outline overflow-ellipsis"
      style="overflow: hidden"
    >
      <!-- Filter Name Content -->

      <span v-if="selectedUnits.length === 0" class="text-nowrap">
        {{ unitOrPlotOrEstateType }}
      </span>

      <span
        v-else
        class="flex flex-row overflow-ellipsis"
        style="overflow: hidden; white-space: nowrap"
      >
        <span>
          {{ selectedUnits.length }}
          {{
            selectedUnits.length === 1
              ? unitOrPlotOrEstateType
              : unitOrPlotOrEstateType + "'s"
          }}
          <SelectedIcon id="svgIcon" :size="20" class="ml-2 text-primary" />
        </span>
      </span>

      <svg
        fill="currentColor"
        viewBox="0 0 20 20"
        :class="{
          'rotate-180': displayDropdown,
          'rotate-0': !displayDropdown,
        }"
        class="absolute top-0 right-1 w-5 h-full transition-transform duration-200 transform"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>

    <!-- Filter Drop Down Content -->
    <div
      v-if="displayDropdown"
      v-click-outside="toggleDropdown"
      class="absolute z-20 left-0 origin-top-right shadow-lg"
    >
      <div class="pt-8 pb-5 px-5 bg-white rounded-md shadow-lg">
        <ul
          class="max-h-28 md:max-h-36 lg:max-h-36 px-4 pt-4 overflow-y-scroll font-normal tracking-wider cursor-pointer"
        >
          <li
            v-for="unitType in unitTypes"
            :key="unitType"
            class="mb-2 inline-flex items-center whitespace-nowrap"
          >
            <input
              id="checkboxIcon"
              v-model="selectedUnits"
              :value="unitType"
              @change="setUnit"
              type="checkbox"
              class="rounded border-surface-300 text-primary mr-2 shadow-sm cursor-pointer"
            />
            <span>{{ unitType }}</span>
          </li>
        </ul>
        <div class="px-4 pb-4">
          <button
            @click="toggleDropdown"
            class="bg-primary hover:bg-primary-600 p-1 mt-2 w-full border rounded text-white"
          >
            <span> Done </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectedIcon from "vue-material-design-icons/CheckboxMarked.vue";
import { mapGetters } from "vuex";
import { unitsCollection } from "@/firebaseCompat.js";

export default {
  name: "MainUnitReserveListFilterUnitType",
  props: {
    propUnits: {
      default: Array,
    },
    settings: {
      type: Object,
    },
  },
  components: {
    SelectedIcon,
  },
  data: () => ({
    stopListening: undefined,
    displayDropdown: false,
    selectedUnits: [],
    unitTypes: [],
    dropdownRevealedAt: null,
  }),
  methods: {
    toggleDropdown(event) {
      // ensures that we don't toggle twice during the same event
      if (event.timeStamp === this.dropdownRevealedAt) return;

      this.dropdownRevealedAt = event.timeStamp;
      this.displayDropdown = !this.displayDropdown;
    },
    setUnit() {
      const unitTypes = this.selectedUnits;

      this.$emit("update:unit-filter", unitTypes);
      this.$router.push({
        query: { ...this.$route.query, unitType: unitTypes },
      });
    },
  },
  created() {
    this.stopListening = unitsCollection.orderBy("type").onSnapshot((snap) => {
      let unitTypesStudio = [];
      let unitTypes = [];

      snap.forEach((doc) => {
        const unitType = doc.data().type;
        if (unitType.includes("Studio")) {
          unitTypesStudio.push(unitType);
        } else {
          unitTypes.push(unitType);
        }
      });

      this.unitTypes = [
        ...Array.from(new Set(unitTypesStudio)),
        ...Array.from(new Set(unitTypes)),
      ];
    });
  },
  computed: {
    ...mapGetters({
      units: "units",
      globals: "globals",
    }),
    unitOrPlotOrEstateType: function () {
      if (
        (this.globals[0].layoutStyle[0] === "floor" ||
          this.globals[0].layoutStyle[1] === "floor" ||
          this.globals[0].layoutStyle[2] === "floor") &&
        (this.globals[0].layoutStyle[0] === "plot size" ||
          this.globals[0].layoutStyle[1] === "plot size" ||
          this.globals[0].layoutStyle[2] === "plot size") &&
        (this.globals[0].layoutStyle[0] === "estate" ||
          this.globals[0].layoutStyle[1] === "estate" ||
          this.globals[0].layoutStyle[2] === "estate")
      ) {
        return "Unit Type / Phase / Type";
      } else if (
        this.globals[0].layoutStyle[0] === "floor" ||
        this.globals[0].layoutStyle[1] === "floor" ||
        this.globals[0].layoutStyle[2] === "floor"
      ) {
        return "Unit Type";
      } else if (
        this.globals[0].layoutStyle[0] === "plot size" ||
        this.globals[0].layoutStyle[1] === "plot size" ||
        this.globals[0].layoutStyle[2] === "plot size"
      ) {
        return "Phase";
      } else if (
        this.globals[0].layoutStyle[0] === "estate" ||
        this.globals[0].layoutStyle[1] === "estate" ||
        this.globals[0].layoutStyle[2] === "estate"
      ) {
        return "Type";
      }
      return "Unit Type";
    },
  },
  mounted() {
    this.selectedUnits = this.propUnits;
  },
  beforeUnmount() {
    if (this.stopListening) this.stopListening();
  },
  watch: {
    propUnits: {
      handler() {
        this.selectedUnits = this.propUnits;
      },
      deep: true,
    },
  },
};
</script>

<style>
#checkboxIcon {
  color: var(--button-background-color);
}

#checkboxIcon:focus {
  --checkbox-opacity: 0.5;
  outline-color: var(--button-background-color) var(--checkbox-opacity);
  outline-style: outset;
}

#svgIcon {
  color: var(--button-background-color);
}
</style>
