import App from "../views/app/App.vue";
import Home from "../views/app/Home.vue";
import firebase from "firebase/compat/app";
import { profilesCollection } from "@/firebaseCompat.js";
import { hasPermission } from "@/permissions.js";
import * as Sentry from "@sentry/vue";
import { createRouter, createWebHistory } from "vue-router";
import { ERRORS, REDIRECT_QUERY_KEY } from "@/constants/index.js";
import { useUserStore, STATUS } from "@/state";
import { firestoreStore } from "@/utils/index.js";

const routes = [
  {
    name: "login",
    path: "/login",
    component: () => import("../views/global/Login.vue"),
  },
  {
    name: "reset-password",
    path: "/resetpassword",
    component: () => import("../views/global/ResetPassword.vue"),
  },
  {
    name: "register",
    path: "/register",
    component: () => import("../views/global/Register.vue"),
  },
  {
    path: "/",
    component: App,
    redirect: { name: "App" },
    meta: {
      requiresAuthData: true,
    },
    children: [
      {
        name: "home",
        path: "",
        component: Home,
        meta: {
          requiresAuthData: true,
        },
      },
      {
        name: "unit",
        path: "unit/:name",
        component: () => import("../views/app/Unit.vue"),
      },
      {
        name: "unitEstate",
        path: "estate/:name",
        component: () => import("../views/app/UnitEstate.vue"),
      },
      {
        name: "onedayonlyunit",
        path: "onedayonly/:name",
        component: () => import("../views/app/Unit.vue"),
      },
      {
        name: "reserve",
        path: "reserve/:name",
        component: () => import("../views/app/Reserve-2.vue"),
      },
      {
        name: "profile",
        path: "profile",
        component: () => import("../views/app/Profile.vue"),
      },
      {
        name: "thankyou",
        path: "/thankyou",
        component: () => import("../views/app/ThankYou.vue"),
      },
      {
        name: "cancel",
        path: "/cancel/:id",
        component: () => import("../views/app/Cancel.vue"),
      },
      {
        path: "/survey",
        name: "survey",
        component: () => import("../views/app/Survey.vue"),
      },
    ],
  },
  {
    path: "/admin",
    component: () => import("../views/admin/Admin.vue"),
    redirect: { name: "admin" },
    meta: {
      requiresAuthData: true,
      requiresAdminRole: true,
    },
    children: [
      {
        name: "admin",
        path: "dashboard",
        component: () => import("../views/admin/Dashboard-v2.vue"),
        meta: {
          area: "dashboard",
          action: "view",
        },
      },
      {
        name: "admin-units",
        path: "units",
        component: () => import("../views/admin/Units"),
        meta: {
          area: "units",
          action: "view",
        },
      },
      {
        name: "admin-import",
        path: "import",
        component: () => import("../views/admin/ImportUnits.vue"),
        meta: {
          area: "units",
          action: "import",
        },
      },
      {
        name: "admin-import-estate",
        path: "import-estate",
        component: () => import("../views/admin/ImportEstate.vue"),
        meta: {
          area: "units",
          action: "import",
        },
      },
      {
        name: "admin-add-unit",
        path: "unit",
        component: () => import("../views/admin/Unit.vue"),
        meta: {
          area: "units",
          action: "add",
        },
      },
      {
        name: "admin-update-unit",
        path: "unit/:id",
        component: () => import("../views/admin/Unit.vue"),
        meta: {
          area: "units",
          action: "update",
        },
      },
      {
        name: "admin-deals",
        path: "deals",
        component: () => import("../views/admin/Deals"),
        meta: {
          area: "units",
          action: "view",
        },
      },
      {
        name: "admin-plans",
        path: "plans",
        component: () => import("../views/admin/Plans/Plans.vue"),
        meta: {
          area: "plans",
          action: "view",
        },
      },
      {
        name: "admin-plan",
        path: "plans/:id",
        component: () => import("../views/admin/Plans/Plan.vue"),
        meta: {
          area: "plans",
          action: "update",
        },
      },
      {
        name: "admin-profiles",
        path: "profiles",
        component: () => import("../views/admin/Profiles.vue"),
        meta: {
          area: "profiles",
          action: "view",
        },
      },
      {
        name: "admin-update-profile",
        path: "profiles/:id",
        component: () => import("../views/admin/Profile.vue"),
        meta: {
          area: "profiles",
          action: "update",
        },
      },
      {
        name: "admin-agents",
        path: "agents",
        component: () => import("../views/admin/Agents.vue"),
        meta: {
          area: "agents",
          action: "view",
        },
      },
      {
        name: "admin-add-agent",
        path: "agents",
        component: () => import("../views/admin/Agent.vue"),
        meta: {
          area: "agents",
          action: "add",
        },
      },
      {
        name: "admin-update-agent",
        path: "agent/:id",
        component: () => import("../views/admin/Agent.vue"),
        meta: {
          area: "agents",
          action: "update",
        },
      },
      {
        name: "admin-users-spamming",
        path: "users-spamming",
        component: () => import("../views/admin/StopSpammingUsers.vue"),
        meta: {
          area: "users",
          action: "view",
        },
      },
      {
        name: "admin-settings",
        path: "settings",
        component: () => import("../views/admin/Settings.vue"),
        meta: {
          area: "settings",
          action: "view",
        },
      },
      {
        name: "admin-extra-settings",
        path: "extraSettings",
        component: () => import("../views/admin/ExtrasSettings.vue"),
        meta: {
          area: "extraSettings",
          action: "view",
        },
      },
      {
        name: "admin-data",
        path: "data",
        component: () => import("../views/admin/Data.vue"),
        meta: {
          area: "data",
          action: "view",
        },
      },
      {
        name: "admin-email-templates",
        path: "email-templates",
        component: () => import("../views/admin/EmailTemplates.vue"),
        meta: {
          area: "email-templates",
          action: "view",
        },
      },
      {
        name: "admin-add-email-template",
        path: "email-template",
        component: () => import("../views/admin/EmailTemplate.vue"),
        meta: {
          area: "email-templates",
          action: "add",
        },
      },
      {
        name: "admin-update-email-template",
        path: "email-templates/:id",
        component: () => import("../views/admin/EmailTemplate.vue"),
        meta: {
          area: "email-templates",
          action: "update",
        },
      },
      {
        name: "admin-downloads",
        path: "downloads",
        component: () => import("../views/admin/Downloads.vue"),
        meta: {
          area: "downloads",
          action: "view",
        },
      },
      {
        name: "admin-landing",
        path: "landing",
        component: () => import("../views/admin/Landing.vue"),
        meta: {
          area: "landing",
          action: "update",
        },
      },
      {
        name: "admin-attendants",
        path: "attendants",
        component: () => import("../views/admin/SocialChatSettings.vue"),
        meta: {
          area: "attendants",
          action: "view",
        },
      },
      {
        name: "admin-add-attendant",
        path: "attendants",
        component: () => import("../views/admin/SocialChatSetting.vue"),
        meta: {
          area: "attendants",
          action: "add",
        },
      },
      {
        name: "admin-update-attendant",
        path: "attendant/:id",
        component: () => import("../views/admin/SocialChatSetting.vue"),
        meta: {
          area: "attendants",
          action: "update",
        },
      },
      {
        name: "admin-survey",
        path: "survey",
        component: () => import("../views/admin/Survey.vue"),
        meta: {
          area: "survey",
          action: "view",
        },
      },
      {
        name: "admin-cta-cards",
        path: "cta-cards",
        component: () => import("../views/admin/CTACards/CTACards.vue"),
        meta: {
          area: "cta-cards",
          action: "view",
        },
      },
      {
        name: "admin-cta-card",
        path: "cta-card/:id",
        component: () => import("../views/admin/CTACards/CTACard.vue"),
        meta: {
          area: "cta-cards",
          action: "update",
        },
      },
      {
        name: "admin-integrations",
        path: "integrations",
        component: () => import("../views/admin/Integrations/index.js"),
        meta: {
          area: "integrations",
          action: "view",
        },
      },
      {
        name: "admin-theme",
        path: "theme",
        component: () => import("../views/admin/Theme.vue"),
        meta: {
          area: "theme",
          action: "view",
        },
      },
      {
        name: "admin-account",
        path: "account",
        component: () => import("../views/admin/Account"),
        meta: {
          area: "account",
          action: "view",
        },
      },
      {
        name: "admin-history",
        path: "history",
        component: () => import("../views/admin/History.vue"),
        meta: {
          area: "history",
          action: "view",
        },
      },
    ],
  },
  {
    name: "stopSpammingUser",
    path: "/stopSpammingUser",
    component: () => import("../views/global/stopSpammingUser.vue"),
  },
  {
    name: "MaintenancePage",
    path: "/MaintenancePage",
    component: () => import("../views/global/MaintenancePage.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("../views/global/404.vue"),
  },
];

// Added this because I got tired of adding console logs to the routes
const DEBUG_MODE = false;

function debugLog(...args) {
  if (DEBUG_MODE) {
    console.log(...args);
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
  scrollBehavior(_, __, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

async function waitForRefValue(ref) {
  return new Promise((resolve) => {
    const checkValue = () => {
      if (ref.value !== null) {
        resolve();
      } else {
        // If the ref is not yet populated, keep checking
        requestAnimationFrame(checkValue);
      }
    };
    checkValue();
  });
}

const {
  state: { globalSettings },
} = firestoreStore();

await waitForRefValue(globalSettings);

let requiresAuth;
let requiresAdminRole;
let authenticatedUser;

// CONFIG (some of this should likely be moved to a store)
router.beforeEach(async (to) => {
  debugLog("CONFIG>>");

  requiresAuth = to.matched.some((record) => record.meta.requiresAuthData);
  requiresAdminRole = to.matched.some(
    (record) => record.meta.requiresAdminRole,
  );

  const { status, user } = useUserStore();
  if (status === STATUS.LISTENING) {
    authenticatedUser = user;
    return;
  }

  const firebaseUser = await new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });

  if (!firebaseUser || firebaseUser.isAnonymous) return;

  try {
    const doc = await profilesCollection.doc(firebaseUser.uid).get();
    const data = doc.data();
    if (!doc.exists || data === undefined) {
      throw new Error(ERRORS.PROFILE_NOT_FOUND);
    }
    authenticatedUser = data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});

// ADMIN GUARD
router.beforeEach(async (to) => {
  debugLog("ADMIN>>");
  if (!requiresAdminRole) {
    return;
  }

  if (!authenticatedUser) {
    // not a logged in user, kick 'em
    return { name: "login" };
  }

  // TODO: this should rather be a whitelist instead of a blacklist
  if (authenticatedUser.role === "user") return { name: "login" };

  const meta = to.matched[to.matched.length - 1].meta;

  if (!meta || !hasPermission(meta.area, meta.action, authenticatedUser.role))
    return { name: "not-found" };
});

// ANON GUARD
router.beforeEach(async ({ path, query }) => {
  debugLog("ANON>>");

  if (!globalSettings.value.dataCollection) return;

  if (!requiresAuth) return;

  if (authenticatedUser) return;

  return {
    name: "login",
    query: {
      ...query,
      [REDIRECT_QUERY_KEY]: path !== "/" ? path.slice(1) : undefined,
    },
  };
});

// MAINTENANCE GUARD
router.beforeEach(async () => {
  debugLog("MAINTENANCE>>");
  if (requiresAuth) {
    if (
      // showFailToPay is basically showMaintenance
      globalSettings.value.showFailToPay &&
      (!authenticatedUser || authenticatedUser.role === "user")
    ) {
      // only users that don't have the role "user" are allowed to bypass
      return { name: "MaintenancePage" };
    }
  }
});

// SPAM GUARD
router.beforeEach(async () => {
  debugLog("SPAM>>");
  if (requiresAuth) {
    if (
      authenticatedUser &&
      authenticatedUser.stopSpamming &&
      authenticatedUser.stopSpamming === true
    ) {
      return { name: "stopSpammingUser" };
    }
  }
});

// Fixing chunk loading issues with this bit lifted from https://stackoverflow.com/a/74861436
router.onError((error, to) => {
  debugLog("ON_ERROR>>");
  if (
    // only reload the page if .js files not found, we don't want a reload if it's a .vue file that can't load (which might happen during dev).
    (error.message.includes("Failed to fetch dynamically imported module") &&
      error.message.endsWith(".js")) ||
    error.message.includes("Importing a module script failed") ||
    error.message.includes("Unable to preload CSS for") // not 100% sure if this will result in a loop or not
  ) {
    console.warn("New deployment detected.");
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location = to.fullPath;
    }
  }
});

export default router;
