export const FIREBASE_ERROR_CODES = Object.freeze({
  USER_NOT_FOUND: "auth/user-not-found",
  WRONG_PASSWORD: "auth/wrong-password",
  INVALID_CREDENTIAL: "auth/invalid-credential",
  TOO_MANY_REQUESTS: "auth/too-many-requests",
  REQUIRES_RECENT_LOGIN: "auth/requires-recent-login",
});

export const ERRORS = Object.freeze({
  DEFAULT: "Something went wrong.",
  REFRESH: "Please refresh the page and try again.",
  INVALID_EMAIL: "Please enter a valid email address.",
  INVALID_PASSWORD:
    "Please enter a valid password. Must include more than 7 characters.",
  INVALID_LOGIN_DETAILS: "Invalid login details, please try again.",
  [FIREBASE_ERROR_CODES.USER_NOT_FOUND]:
    "This email does not exist on our system. Please register.",
  PROFILE_NOT_FOUND: "Profile not found.",
  ACCOUNT_ACCESS_DISABLED:
    "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
  PROFILE_MALFORMED:
    "Your profile was not set up correctly. Please re-register.",
  SENSITIVE_OPERATION:
    "This operation is sensitive and requires recent authentication. Log in again before retrying this request.",
  GLOBAL_SETTINGS_NOT_FOUND: "Global settings not found.",
  THEME_SETTINGS_MALFORMED: "Theme settings are malformed.",
  ACCOUNT_SETTINGS_MALFORMED: "Account settings are malformed.",
  LANDING_DEFAULT_MALFORMED: "Landing default settings are malformed.",
});
